#user-info-container {
  width: 100% !important;
  margin-top: 20px;
}
.device-detail-container {
  padding: 20px;
}
.search_patient_container {
  margin-bottom: 10px;
  text-align: left;
}
.point_input {
  width: 60px;
}

.point_btn {
  width: 100px;
}

.userid_container_box {
  margin-left: 10px;
  text-align: left;
  margin-bottom: 10px;
}
.time_picker_box {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}
.time_picker_span {
  margin-left: 10px;
  margin-right: 10px;
}
.Segment_simulate {
  overflow-y: hidden !important;
}
.simulate_sock_right_box {
  width: 45%;
  float: right;
  text-align: left;
}
.simulate_sock_left_box {
  width: 45%;
  float: left;
}

.simulate_sock_right_box_bk {
  width: 50%;
  float: right;
  text-align: left;
}
.simulate_sock_left_box_bk {
  width: 40%;
  float: left;
}

.simulate_sock_import_btn {
  margin-bottom: 20px;
}

.simulate_ok_button {
  margin-top: 10px !important;
  width: 100px;
}
/* .simulate_upload_button{margin-left: 13%!important;} */
.time_picker_label {
  margin-right: 10px;
}
.simulate_sock_text {
  width: 100%;
  height: 520px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  text-align: left;
  overflow-y: scroll;
  margin-top: 10px;
}
.patient_note_input {
  width: 80%;
  height: 300px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  overflow-y: scroll;
  margin-top: 10px;
  display: inline-block;
  white-space: pre-wrap;
  padding-left: 20px;
  padding-top: 20px;
}
.patient_note_input_new {
  width: 20%;
  height: 200px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  overflow-y: scroll;
  margin-top: 10px;
  display: inline-block;
  white-space: pre-wrap;
  padding-left: 20px;
  padding-top: 20px;
  float: left;
  margin-left: 30%;
}
.files_box {
  margin: 0 0.25em 0 10% !important;
}
.simulate_sock_center_box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -14px;
  margin-top: -11px;
}
.right_icon {
  cursor: pointer;
}
.simulate_button_box {
  position: relative;
  margin-top: 50px;
  text-align: right;
  margin-right: -10%;
}
.simulate_download_button {
  position: absolute;
  left: 72.5%;
  bottom: 0px;
}
