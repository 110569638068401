.weight-flex-info {
  display: flex;
  height: 330px;
}
.weight-table-info {
  border-radius: 5px;
  /*border: 1px solid #ababab;*/
  width: 50%;
  float: left;
  margin-right: 10px;
  overflow: auto;
}
.weight-chart-info {
  border-radius: 5px;
  /*border: 1px solid #ababab;*/
  width: 50%;
  float: right;
  margin-left: 10px;
}
