.sr-edit-patient {
	text-align: left;
}

.sr-edit-patient .form-section-title {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 10px;
	color: rgba(0, 0, 0, 0.87);
}

.sr-edit-patient form .ant-form-item-label {
	line-height: unset;
}

.sr-edit-patient .form-section:first-child {
	margin-top: 20px;
}

.sr-edit-patient .form-section {
	margin-top: 42px;
}

.sr-edit-patient form .form-item {
	margin-bottom: 0;
}

.sr-edit-patient .phone-button {
	height: 40px !important;
}

.sr-edit-patient .phone-input {
	width: 100% !important;
	height: 40px !important;
	border-color: rgb(217, 217, 217) !important;
	font-size: 16px !important;
}
