.selectContainer{position: relative;border: 1px solid #d9d9d9;border-radius: 4px;height: 32px;padding: 0 15px 0 10px;display: inline-block;}
.selectBox{ background-color: #fafafa;padding: 0 10px 0 10px;border: 1px solid #e8e8e8;
  border-radius: 2px;}
.selectBox_bk{ background-color: #f5f5f5;padding: 0 10px 0 10px;}
.CloseOutlined{color: rgba(0, 0, 0, 0.45); font-weight: bold; font-size: 12px;}
.selectValue{font-size: 14px; color: rgba(0, 0, 0, 0.65);
  margin-right: 4px;
  height: auto;}
.selectValuesBox{margin-top: 3px;}
.Checkbox{margin-left: 8px;}
.CheckboxGroupBox{margin-top: 4px;margin-left: -12px;background-color: #fff;z-index: 999;height: 250px;overflow-y: auto;display: block;}
.selectInputSearch{width: 214px;margin-top: 20px;margin-bottom: 10px;margin-left: 8px;border-radius: 4px;}
.blanck_SelectBox{display: inline-block;margin-left: 5px;padding: 0 10px 0 10px;}
