.tableFooterRow {
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: inline-table;
  color: white;
  font-weight: 600;
  background-color: rgb(45, 1, 114);
  margin-top: -15px;
}

.optionStyle {
  width: 200px;
}

.check_all > .ant-checkbox .ant-checkbox-inner {
  border-width: 2px;
}
