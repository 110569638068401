#new-account {
	margin: 40px 15%;
	text-align: left;
}

#new-account form .ant-form-item-label {
	line-height: unset;
}

#new-account .form-section:first-child {
	margin-top: 20px;
}

#new-account .form-section {
	margin-top: 42px;
}

#new-account form .form-item {
	margin-bottom: 0;
}

#new-account .phone-button {
	height: 40px;
}

#new-account .phone-input {
	width: 100%;
	height: 40px;
	border-color: rgb(217, 217, 217);
	font-size: 16px;
}

#new-account .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #06C791;
}

#new-account .ant-steps-item-finish .ant-steps-item-icon {
	border-color: #06C791;
	background-color: #06C791;
}
#new-account .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #06C791;
}
#new-account .ant-steps-item-process .ant-steps-item-icon {
	background-color: #06C791;
	border-color: #06C791;
}

#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
	color: #06C791;
}
#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
	border-color: #06C791;
}
#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
	color: #06C791;
}

#new-account .anticon {
	display: inline-block;
	color: white;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
