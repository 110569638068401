.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #FFF; }

.device-detail-container #meta-grid {
  margin-bottom: 10px; }

.device-detail-container .user-info-container .ui.grid > .stretched.row {
  padding: 0.2rem 0; }
  .device-detail-container .user-info-container .ui.grid > .stretched.row > .column {
    padding: 0.4rem; }

.device-detail-container .user-info-container .seg-title {
  margin-bottom: 0.5rem; }

.device-detail-container .wear-status-container .ui.grid {
  margin-top: 0.4rem; }
  .device-detail-container .wear-status-container .ui.grid > .row {
    padding: 0.2rem 0; }
    .device-detail-container .wear-status-container .ui.grid > .row > .column {
      padding: 0.4rem; }

.device-detail-container #sock-status-container table {
  font-size: 1rem; }
  .device-detail-container #sock-status-container table td {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem; }

.device-detail-container #canvas-container {
  width: 100%; }
  .device-detail-container #canvas-container #sock-realtime {
    width: 100%;
    height: 100%; }

.device-detail-container .wear-history-container {
  margin: 0.5rem 0; }
  .device-detail-container .wear-history-container .lbl-wear-history-start-date {
    margin-right: 0.5rem;
    margin-bottom: 1rem; }
.device-detail-container{padding: 20px;}
.checkboxlabel{margin-right: 20px;}
.checkboxlabel input{vertical-align: middle!important;}
.kongge{margin-left: 5px;}
.ui.segment{overflow-y:scroll}
#user-leftinfo{width: 60%;float: left;}
#user-info-container2{width: 38%;height:915px;float: right;margin-left: 2%;cursor: pointer;
  border-radius: 5px;box-shadow: 1px 1px 4px 0 rgba(177, 11, 11, 0.15);}
.device-detail-container{height: 100%; padding: 0; margin: 0;}
#user-info-container3{width: 100%;margin-top: 15px;border-radius: 5px;
  box-shadow: 1px 1px 4px 0 1px 1px 4px 0 rgba(177, 11, 11, 0.15);
  padding: 10px 0 0;
}
.details-report-calendar{width: 100%;margin-top: 15px;border-radius: 5px;
  box-shadow: 1px 1px 4px 0 1px 1px 4px 0 rgba(177, 11, 11, 0.15);
  padding: 10px 0 0;
}
#user-info-container4{clear: both;}
.userbox1{width: 44%;height: 450px;cursor: pointer;
  border-radius: 5px;margin-right: 1%;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);float: left;}
.imgboxtotal{height: 300px;width: 100%;}
.imgbox1{float: left;width: 38%;margin-left: 10%}
.imgbox2{float: right;width: 38%;margin-right: 10%}
.seg-title2{text-align: left;;height: 26px;background: #eee;;margin-bottom: 3px;}
.box1header2{margin-left: 34px;}
.box1{text-align: center;height: 40px;margin:0!important;}
.box1header{font-weight: 700;line-height: 40px;}
.userbox2{width: 55%;height: 450px;float: left;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;}
.userbox4{clear: both;}
.userbox3{width: 100%;height: 450px;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;margin-top: 15px;text-align: left;}
.userbox3-text1box{height: 40px;border-bottom: 1px solid rgba(0,0,0,0.15);}
.userbox3-text1{margin-left: 34px;font-weight: 700;line-height: 40px;}
.userbox3-text2{margin-left: 34px;font-weight: 700;line-height: 40px;height: 40px;color: #9fa9b9;}
.userbox6{width: 100%;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;margin-top: 15px;}
.userbox6 img{width: 100%;}
.userbox3-imgbox{width: 100%;height: 370px;}
/* .userbox3-img1{width: 38%;float: left;margin-left: 7% }
.userbox3-img2{width: 38%;float: right;margin-right: 7% } */
.userbox3-img1{width: 38%;float: left;height: 90%;margin-left: 7%;}
.userbox3-img2{width: 38%;float: right;height: 90%;margin-right: 7%}
.userbox3-text2-span{float: left;}
.userbox3-text2-button{float: right;}
.react-calendar{width: 100%!important;line-height: 1.5em!important;}
.ico1{width: 10px;height: 10px;border:2px solid #dab4fd!important;border-radius: 20%;top:50%;margin-top:-5px;position: absolute}
.ico1text{margin-left: 20px;;line-height: 25px;}
.ico1box{height: 25px;position: relative;width: 80%;margin: 0 auto;margin-top: 10px;text-align: left;}
.ico2{width: 10px;height: 10px;border-radius: 50%;background-color: #e2d956;top:50%;margin-top:-5px;position: absolute}
.ico2text{margin-left: 20px;;line-height: 25px;}
.ico3{width: 10px;height: 10px;border-radius: 50%;background-color: rgb(252, 13, 27,1);top:50%;margin-top:-5px;position: absolute}
.ico3text{margin-left: 20px;;line-height: 25px;}
/* .react-calendar__tile--now{background-color:rgba(218, 180, 253,0.6)!important;} */
.react-daterange-picker__calendar .react-calendar__tile--rangeStart{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;}
.react-daterange-picker__calendar .react-calendar__tile--rangeEnd{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;
}
.react-calendar__tile--active{background-color:rgba(0,0,0,.1)!important;border: 1px solid #a989c5!important;color:black!important;border-radius: 20%;}
/* .react-calendar__tile--hasActive{color:white;background-color:#a989c5!important;} */
.react-calendar__tile--hover {background-color: #a989c5;}
.normalmetabase2{background-color: rgba(15, 127, 18,1)!important;color:white!important;}
.normalmetabase{background-color: rgba(15, 127, 18,0.6)!important;color:white!important;}
.yellowmetabase2{background-color: rgb(255, 253, 56,1)!important;color:rgb(94, 29, 29)!important;}
.yellowmetabase{background-color: rgb(255, 253, 56,0.6)!important;color:black!important;}
.redmetabase2{background-color: rgb(252, 13, 27, 1)!important;color:white!important;}
.redmetabase{background-color: rgb(252, 13, 27, 0.6)!important;color:white!important;}
.react-daterange-picker__inputGroup__month{width: 28px!important;}
.react-daterange-picker__inputGroup__year{width: 48px!important;}
.react-daterange-picker__inputGroup__day{width: 28px!important;}
.urlstyle{float: right;}
.urlbox{width: 100%;text-align: center}
.haveimage{border:2px solid #dab4fd!important;border-radius: 20%;}
.device-detail-container{padding-left: 20px!important;padding-right: 20px!important;}
.complianceBlank{width: 100%;height: 310px;}
.timeMore{background-color: rgb(78, 25, 116)!important;color:white!important;}