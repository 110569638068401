.sockHome-container {
  padding: 20px !important;
}
.docReport-container {
  padding-left: 20px;
  padding-right: 20px;
}
.battery_container {
  padding-left: 20px;
  padding-right: 20px;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
  color: black;
  margin-left: 10;
}
textarea{padding: .67857143em 1em;}
