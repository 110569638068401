.report_table_box{text-align: left;}
.report_table_box_one{text-align: left;}
.report_title_box_two{text-align:center;}
.report_title_box_three{text-align: right;}
.report_title_box{display: inline-block;width: 33%;}
.report_title_box_four{text-align: left;margin-top: 6px;margin-bottom: 4px;}
.report_title{font-weight: 900;color: #000;}
#detail_report_dowload_container{padding-top: 70px;}
.report_table{border: 1px solid #000!important;border-bottom: none!important;
    border-radius: 0!important;margin-top: 0px!important;}
.report_table_th_first{border-radius: 0!important;border-bottom: 1px solid #000!important;background-color: #b7b7b7!important;}
.report_table_th{border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #b7b7b7!important;}
.report_table_th_last{border-radius: 0!important;}
.report_table_td_first{border-bottom: 1px solid #000!important;}
.report_table_td{border-bottom: 1px solid #000!important;border-left: 1px solid #000!important;}
.detail_report_title_content{margin-left: 5px;color: #000;}
.details_report_table{margin-bottom: 30px!important;}