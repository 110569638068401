.confirm-dlg {
  display: inline-block; }
.ReactTable{text-align: center;}
.regiSuccess{text-align: center;}
.regiSuccessTitle{font-size: 18px;font-weight: 600;}
.regiSuccesstext {font-weight: 500;margin-bottom: 50px;}
#regiSuccessIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 30px;
  margin-top: 10px;
}
