.note_comments{height: 150px;}
.note_title_table{color: #69c;font-weight: 600;cursor: pointer;}

.two_line_ellipses {
	display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}
