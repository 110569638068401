body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactTable .rt-tbody .rt-tr.row-clickable:hover {
  border-top: solid 1px #bfc9cf;
  border-bottom: solid 1px #bec6ca;
  cursor: pointer;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,0.02);
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.sr-required:after {
  content: '*';
  color: red;
  margin-left: 4px;
}

.sr-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.success_box{
  margin: 0 auto;
  text-align: center;
}
#siteIcon {
  font-size: 130px;
  color: #4a90e2;
  margin-bottom: 60px;
  margin-top: 20px;
}
.siteTitle {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.finishtext {
  font-weight: 500;
  margin-bottom: 50px;
}
.add-box-form-container .sock-add-icon-container {
  display: inline-block; }
  .add-box-form-container .sock-add-icon-container .sock-add-icon {
    margin-left: 10px;
    cursor: pointer; }

.add-box-form-container .v-hidden {
  visibility: hidden; }

.add-box-form-container .v-visible {
  visibility: visible; }

.ant-checkbox .ant-checkbox-inner {
  /*width: 25px;*/
  /*height: 25px;*/
  /*background-color: red;*/
  border-color: #aaa;
}

*{padding: 0;margin: 0;}
.box-list-container .box-controll-container {
  margin-bottom: 10px; }
  .box-list-container .box-controll-container button {
    float: right; }

.box-list-container h2 {
  display: inline-block; }

#box-search-bar {
  float: right;
  margin-left: 10px; }

.search-item-container .search-items {
  margin: 5px 0;
  width: 100%; }

#search-bar {
  font-size: 14px; }
  #search-bar + i {
    font-size: 14px; }
.box-list-container{padding:20px!important;}
.boxlistdiv{text-align: center;}
.add_import_box{text-align: right;margin-bottom: 10px;margin-top: 10px;}
.confirm-dlg {
  display: inline-block; }
.ReactTable{text-align: center;}
.regiSuccess{text-align: center;}
.regiSuccessTitle{font-size: 18px;font-weight: 600;}
.regiSuccesstext {font-weight: 500;margin-bottom: 50px;}
#regiSuccessIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 30px;
  margin-top: 10px;
}

input.date-input:invalid {
  border-color: red !important;
}
.deleteButton{background-color: #db2828!important;color: #fff!important;}
.okButton{width: 104px!important;}
.removeButton{width: 104px!important;}
.buttonBox{text-align: center!important;}
.circle_status_icon {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin: auto;
	vertical-align: middle;
	display: inline-block;
}
.replace_input_container {
  text-align: left;
}
.replace_input_label {
  margin-right: 20px;
}
.replace_input_content {
  margin-right: 20px;
}
.replace_input_box {
  margin-bottom: 20px;
}
.replace_button_box {
  margin-top: 20px;
}
.replace_button_save {
  margin-right: 20px !important;
}
.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #fff;
}

.device-detail-container #meta-grid {
  margin-bottom: 10px;
}

.device-detail-container .user-info-container .ui.grid > .stretched.row {
  padding: 0.2rem 0;
}
.device-detail-container
  .user-info-container
  .ui.grid
  > .stretched.row
  > .column {
  padding: 0.4rem;
}

.device-detail-container .user-info-container .seg-title {
  margin-bottom: 0.5rem;
}

.device-detail-container .wear-status-container .ui.grid {
  margin-top: 0.4rem;
}
.device-detail-container .wear-status-container .ui.grid > .row {
  padding: 0.2rem 0;
}
.device-detail-container .wear-status-container .ui.grid > .row > .column {
  padding: 0.4rem;
}

.device-detail-container #sock-status-container table {
  font-size: 1rem;
}
.device-detail-container #sock-status-container table td {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.device-detail-container #canvas-container {
  width: 100%;
}
.device-detail-container #canvas-container #sock-realtime {
  width: 100%;
  height: 100%;
}

.device-detail-container .wear-history-container {
  margin: 0.5rem 0;
}
.device-detail-container .wear-history-container .lbl-wear-history-start-date {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
.problemdetail {
  text-align: left;
}
.ui.segment {
  height: auto !important;
}
.seg-title {
  text-align: left;
  margin-bottom: 30px;
}
.floattext {
  text-align: left;
  width: 100%;
}
.lefttext {
  width: 49%;
  float: left;
  margin: 0;
}
.righttext {
  width: 49%;
  float: right;
  margin: 0;
}
.Statistics-container {
  padding: 20px;
}
#return-to-device-list {
  position: fixed;
  right: 0;
  z-index: 999;
}

.inputBoxId {
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
}

.inputBoxIdLabel {
  float: left;
  margin-left: 20px;
  margin-right: 10px;
}

.inputBoxIdContent {
  width: 250px;
}

.device-list-container .view-more-eye {
  cursor: pointer; }

.device-list-container .icon-comments-edit {
  margin-left: 5px; }

.device-list-container .view-action-history {
  cursor: pointer;
  margin-left: 4px; }

.device-list-container h2 {
  display: inline-block; }

#user-search-bar {
  float: right; }

.search-item-container .search-items {
  margin: 5px 0;
  width: 100%; }

#search-bar {
  font-size: 14px; }
  #search-bar + i {
    font-size: 14px; }

.ui.table.result-table {
  margin-top: 0; }

.device-summary-container {
  max-width: 800px; }
  .device-summary-container .ui.grid > .row {
    padding: 0.5rem; }

.device-action-history {
  max-width: 1000px; }
.device-action-history .ui.grid > .row {
    padding: 0.5rem; }
.sockstatus{cursor: pointer;}
.totalbox{position: relative;}
.updownbox{position: absolute;right: 0;top: 40%;cursor: pointer;}
.upbox{position: absolute}
.Nav__nav___nBWON{padding: 4px!important;}
.rt-resizable-header-content{white-space: normal;}/* 强制换行 */
.rt-resizable-header{align-items:center;display: -webkit-flex;justify-content:center; }
.ReactTable .rt-thead.-headerGroups{display: none;}
.ReactTable .rt-thead .rt-resizable-header{overflow: hidden;}
.spanonclick{color:#6699CC;font-weight: 600;}
.spanonclick:hover{text-decoration: underline;}
.edit{margin-left: 5px!important;}
.ifchange2{visibility: hidden!important;}
body{color: rgba(0,0,0,.65);}
.search_patient_container{margin-bottom: 10px;text-align: left;}
.search_label{margin-right: 10px;}
.patient_note_label{margin-right: 10px;font-weight: 600;color: rgba(0, 0, 0, 0.87);}
.patient_note_label_new{margin-right: 10px;font-weight: 600;color: rgba(0, 0, 0, 0.87);text-align: left;margin-top: 20px;}
.search_symbol{margin-left: 5px;margin-right: 5px;}
.search_email_label{margin-left: 10px;}
.search_icon{margin-left: 10px!important;cursor: pointer;}
.search_sirenid_input{width: 70px;}
.condition_other{width: 140px;padding-left: 10px;}
.patient_condition{text-align: left;margin-left: 30%;}
.patient_condition_bk{text-align: left;margin-left: 70%;}
.diagnosis_codes{text-align: left;margin-left: 30%; margin-top: 10%;}
.diagnosis_codes_new{text-align: left;margin-left: 20%; margin-top: 20px;}
.diagnosis_codes_bk{text-align: left;margin-left: 70%; margin-top: 10%;}
.add_diagnosis_code{cursor: pointer;margin: 0!important;padding: 0!important;}

.checkboxVertical{vertical-align: middle!important}
.edit-compliance{text-align: center!important;height: 440px!important;}
.edit-title{text-align: left!important;}
.closeicon{cursor: pointer;}
.reset-image {
    margin: 0 auto;
    width: 650px !important;
    height: 650px !important;
}
.radioLabel {
    margin-left: 16px;
}
.closebutton {
    padding: 0;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
}
.closeicon {
    margin: 0 !important;
    padding: 0 !important;
}
.submitbutton {
    width: 200px;
    height: 50px;
    border: 1px solid #d1d1d1;
    color: #6699cc;
    border-radius: 5px;
    cursor: pointer;
}
.ImageBox {
    padding: 5% !important;
}
.radio {
    margin-left: 24%;
    margin-bottom: 10px;
    width: 100%;
}
.leftImageBox {
    width: 46%;
    float: left;
    background-color: #d8d8d8;
    height: 350px;
}
.rightImageBox {
    width: 46%;
    float: right;
    background-color: #d8d8d8;
    height: 350px;
}
.viewImage {
    width: 100%;
    height: 350px;
}
.commentLabel {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.commentInput {
    margin-left: 5px;
    margin-top: 20px;
    width: 50%;
    height: 100px;
}
/* .calendar_userbox2{
    height: 450px;float: left;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;}
.calendar_userbox3{
    height: 450px;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;margin-top: 15px;text-align: left;} */
    .ico4{width: 10px;height: 10px;border-radius: 50%;background-color: rgb(78, 25, 116);top:50%;margin-top:-5px;position: absolute}
    .ico4text{margin-left: 20px;line-height: 25px;}
    .timeMore{background-color: rgb(78, 25, 116)!important;color:white!important;}
    .timeMoreLast{background-color: rgba(78, 25, 116,0.6)!important;color:white!important;}
.type_label{margin-right: 40px;}
.type_box{margin-bottom: 20px;}
.mess_label{margin-right: 14px;position: absolute;top: 40%;}
.title_input{width: 300px;}
.mess_input{width: 300px;margin-left: 70px;height: 100px;}
.showSns_box{margin-bottom: 20px;}
.show_box{margin-left: 70px;}
.show_input{width: 200px;}
.modal_box{width: 600px!important;}
.red_tip{position: absolute;width: 180px;right: 0;top: 0px;font-size: 14px;letter-spacing: 0.3px;line-height: 1.5;color: rgba(0, 0, 0, 0.5);}
.normal_tip{position: absolute;width: 180px;right: 0;top: 80px;font-size: 14px;letter-spacing: 0.3px;line-height: 1.5;color: rgba(0, 0, 0, 0.5);}
.type_mess_box{position: relative;}
.mess_box{position: relative;}
.DispensedDayBox{width: 150px!important;height: 38px;}
.DispensedDayBox_center{margin-left: 10px;margin-right: 10px;}
#DispensedMonth{width: 100px!important;}
.dispensed_input_label{line-height: 38px;margin-right: 5px;}
.sockHome-container {
  padding: 20px !important;
}
.docReport-container {
  padding-left: 20px;
  padding-right: 20px;
}
.battery_container {
  padding-left: 20px;
  padding-right: 20px;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
  color: black;
  margin-left: 10;
}
textarea{padding: .67857143em 1em;}

.ant-select-selection--single{height: 100%!important;}
.ant-select-selection{border: 1px solid rgba(0,0,0,0.1)!important;}
.ant-select-search__field{border:none!important;padding: 0px!important;background: transparent!important;}
.ant-select-dropdown-menu-item{font-size: 12px!important;}
.ant-select-dropdown{min-width: -webkit-fit-content;min-width: -moz-fit-content;min-width: fit-content;}
.select_common_style{height: 28px!important;}
.selectStyle .ant-select-selection__rendered{line-height: 40px!important;}
.select_common_style .ant-select-selection__rendered{line-height: 28px!important;}
.confirm-dlg {
  display: inline-block; }
.edit{color: #6699CC;}
.modifyGroup{height: 36px!important;}

.border-container {
  border: 1px solid #b8bcca;
  border-radius: 5px;
  margin-top: 10px;
}

.success_box {
  margin: 0 auto;
  text-align: center;
}
#siteIcon {
  font-size: 130px;
  color: #4a90e2;
  margin-bottom: 60px;
  margin-top: 20px;
}
.siteTitle {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.finishtext {
  font-weight: 500;
  margin-bottom: 50px;
}

.sr-edit-patient {
	text-align: left;
}

.sr-edit-patient .form-section-title {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 10px;
	color: rgba(0, 0, 0, 0.87);
}

.sr-edit-patient form .ant-form-item-label {
	line-height: unset;
}

.sr-edit-patient .form-section:first-child {
	margin-top: 20px;
}

.sr-edit-patient .form-section {
	margin-top: 42px;
}

.sr-edit-patient form .form-item {
	margin-bottom: 0;
}

.sr-edit-patient .phone-button {
	height: 40px !important;
}

.sr-edit-patient .phone-input {
	width: 100% !important;
	height: 40px !important;
	border-color: rgb(217, 217, 217) !important;
	font-size: 16px !important;
}

.step {
  /*border: 1px solid #ababab;*/
  width: 80%;
  margin: 0 auto;
  /*box-shadow: 1px 1px 1px #ababab;*/
  height: 450px;
}
.step_small_space {
  width: 80%;
  margin: 0 auto;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.inputTotal {
  display: block;
  width: 250px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 20px;
}
.step3InputTotal {
  width: 790px !important;
  margin-bottom: 0px !important;
}
.step3InputTotal600 {
  width: 600px !important;
  margin-bottom: 0px !important;
}
.inputLeftTotal {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 250px;
}
.inputrightTotal {
  float: right;
  margin-bottom: 20px;
  width: 250px;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}
#finishIcon {
  font-size: 100px;
  color: #4a90e2;
}
.inputContent {
  width: 250px !important;
  height: 40px !important;
}
.selectStyle {
  width: 250px;
  height: 40px;
}
.step3 {
  /*border: 1px solid #ababab;*/
  width: 80%;
  margin: 0 auto;
  /*box-shadow: 1px 1px 1px #ababab;*/
  height: 500px;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.checkTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
}
#finishIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 10px;
  margin-top: 100px;
}
.finishTitle {
  font-size: 20px;
  font-weight: 600;
}
.finishtext {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 50px;
}
.finishButton {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
}
.doctorAccount {
  border: 1px solid #ababab;
  width: 80%;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #ababab;
}
.createAccount {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-bottom: 10px;
  margin-left: 200px;
}
.customHeight {
  height: 10px;
}
#failedIcon {
  font-size: 80px;
  color: #4a4a4a;
  margin-bottom: 30px;
  margin-top: 80px;
}
.boldLabel {
  font-weight: 800;
}
#successIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 10px;
  margin-top: 100px;
}
#errorIcon {
  font-size: 120px;
  color: #4a4a4a;
  margin-bottom: 10px;
  margin-top: 100px;
}
.step3Title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px !important;
  margin-bottom: 0px !important;
}
.checktext {
  margin-bottom: 30px;
}
.DOBPicker {
  width: 250px;
  height: 32.63px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: none;
}
.step3 {
  height: 600px;
}
.step4_tip {
  margin-left: 25px;
  margin-bottom: 10px !important;
}
.step3_section_title {
  /* text-align: center;
  width: 600px !important; */
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.clear_both {
  clear: both;
}
.Patient_group {
  padding-top: 20px;
}
.Secondary_group {
  padding-top: 40px;
}
.Secondary_group_edit {
  padding-top: 30px;
}
.Site_group {
  padding-top: 40px;
}
.checkTotal .checkbox {
  font-size: 16px;
}
.checkTotal {
  font-size: 16px;
}
.select_month_box {
  width: 80px;
  min-width: 80px !important;
  height: 40px;
}
.select_day_box {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.error_birth_tip {
  color: red;
}

.newSite_box .react-tel-input input[type=tel] {
  width: 250px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 40px;
}
.step_li {
  margin-bottom: 30px;
  margin-left: 20px;
}
.step_li_new {
  margin-bottom: 20px;
  margin-left: 20px;
}
.step_li_no_margin_left {
  margin-bottom: 30px;
}
.step_tip_user {
  margin-bottom: 10px !important;
}
.img_border {
  border: 1px solid #ddd;
}
.selectFullWdith {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
}
.selectFullWdith .ant-select-selection__rendered {
  line-height: 40px !important;
}
.chekboxBottom {
  margin-bottom: 15px !important;
}
.PaInforBox {
  width: 790px !important;
  margin-bottom: 0px !important;
}
.selectMoreStyle {
  width: 290px;
  height: 40px;
}
.selectMoreStyle .ant-select-selection__rendered {
  line-height: 40px !important;
}
.inputLeftNewTotal {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 290px;
}
.inputRightNewTotal {
  float: right;
  margin-bottom: 20px;
  width: 290px;
}
.redAddressTip {
  color: red;
}
.inputDateContent {
  width: 250px;
  height: 40px;
}
.inputDateContent .ant-calendar-picker-input.ant-input {
  height: 40px;
}
.step3_confirm_title {
  text-align: left;
  width: 600px !important;
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.collapse_box {
  border-radius: 10px !important;
  width: 60%;
  margin-left: 20% !important;
  margin-top: 40px !important;
  text-align: left;
  padding: 0px 20px 0px 20px !important;
  color: #000;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
.collapse_panel_title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.panel_seRow_right {
  margin-left: 20%;
}
.collapse_panel_seRow {
  font-size: 16px;
  margin-bottom: 20px;
}
.collapse_panel_dis {
  /*margin-top: 20px !important;*/
  /*font-style: italic;*/
  width: 80%;
}
.collapse_panel_left {
  float: left;
  width: 70%;
}
.collapse_panel_right {
  float: right;
}
.App_Icon {
  display: block;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
}
.ant-collapse-content-box {
  color: #000 !important;
}
.panel_finish_content {
  font-size: 16px;
  margin-bottom: 30px;
  color: #000;
}
.panel_finish_title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px !important;
  margin-top: 50px !important;
  color: #000;
}
.panel_finish_icon {
  font-size: 100px !important;
  color: #02ce9c;
  width: 118px !important;
  line-height: 150px !important;
  height: 150px !important;
}
.inputTotal input {
  font-size: 14px;
}

.step_title {
  font-weight: 600;
  font-size: 18px;
}

.img_p {
  font-size: 14px;
  font-weight: normal;
  word-wrap: normal;
  width: 290px;
  padding-top: 10px;
  margin-left: 30px;
}


.ant-steps-item-process .ant-steps-item-title {
  font-weight: 400;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
  -ms-transform: none;
  font-weight: 900;
  transform: none;
}

#user-info-container{width: 100%!important;}
.commentBox{float: right;width: 39%;height: 450px;cursor: pointer;
    border-radius: 5px;padding: 0 2% 2% 2%;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);}
.userbox1{width: 24%!important;}
.userbox2{width: 35%!important;}
.userbox3{width: 60%!important;}
.commentTitle{margin-top: 14px;margin-bottom: 14px;}
.commentText{width: 100%;height: 60%;margin-bottom: 14px;}
.tipBox{float: left;width: 40%;}
.healthBox{float: right;width: 39%;height: 450px;
    border-radius: 5px;padding: 0 2% 2% 2%;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);margin-top: 15px;}
.userbox3{float: left!important;}
.health_content{width: 100%;background-color: #eee;}
.health_title{width: 33.33%;float: left;cursor: pointer;}
.health_titleBox{height: 36px;line-height: 36px;margin-top: 24px;margin-bottom: 1px;}
.health_content{border: 1px solid rgba(0,0,0,0.15);height: 356px;}
.sureButton{width: 90px!important;height: 34px!important;margin-left: 20px!important;}
.topRecommend{height: 350px;border: 1px solid #ababab;position: relative;justify-content: center;display: -webkit-flex;}
.verHeight{position: absolute;top: 50%;margin-top: -17px;height: 34px;width: 420px;left: 50%;margin-left: -210px;}
.dowButton{margin-bottom: 1rem!important;}
.notification_border {border: 1px solid rgba(0,0,0,0.15);border-radius: 5px;height: 35px;}
.radio_box{margin-left: 10px;margin-right: 10px;text-align: left;margin-top: 10px;float: left;flex: 40% 1;}
.comment_box{margin-top: 20px;text-align: left;flex: 60% 1;margin-left: 50px;}
.radio_textarea{width: 500px;height: 100px;border: 1px solid rgba(34,36,38,.15);border-radius: 5px;padding: 1em 1em;color: rgba(0,0,0,.87);font-size: 1rem;margin-top: 10px;margin-bottom: 10px;}
.review_icon_circle{font-size: 16px!important;}
.reviewSuccess_right_close{font-size: 16px!important;}
.radio_group_title{font-weight: 600;color: #000;font-size: 18px;margin-bottom: 20px;margin-top: 20px;}
.clear_both{clear: both;}
.choice_box{width: 100%;padding-left: 30px;padding-right: 30px;}
.radio_textarea::-webkit-input-placeholder {
    font-style: italic;
}
.radio_textarea:-moz-placeholder {
    font-style: italic;
}
.radio_textarea::-moz-placeholder {
    font-style: italic;
}
.radio_textarea:-ms-input-placeholder {
    font-style: italic;
}
.replace_button_box{text-align: right;}


.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #FFF; }

.device-detail-container #meta-grid {
  margin-bottom: 10px; }

.device-detail-container .user-info-container .ui.grid > .stretched.row {
  padding: 0.2rem 0; }
  .device-detail-container .user-info-container .ui.grid > .stretched.row > .column {
    padding: 0.4rem; }

.device-detail-container .user-info-container .seg-title {
  margin-bottom: 0.5rem; }

.device-detail-container .wear-status-container .ui.grid {
  margin-top: 0.4rem; }
  .device-detail-container .wear-status-container .ui.grid > .row {
    padding: 0.2rem 0; }
    .device-detail-container .wear-status-container .ui.grid > .row > .column {
      padding: 0.4rem; }

.device-detail-container #sock-status-container table {
  font-size: 1rem; }
  .device-detail-container #sock-status-container table td {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem; }

.device-detail-container #canvas-container {
  width: 100%; }
  .device-detail-container #canvas-container #sock-realtime {
    width: 100%;
    height: 100%; }

.device-detail-container .wear-history-container {
  margin: 0.5rem 0; }
  .device-detail-container .wear-history-container .lbl-wear-history-start-date {
    margin-right: 0.5rem;
    margin-bottom: 1rem; }
.device-detail-container{padding: 20px;}
.checkboxlabel{margin-right: 20px;}
.checkboxlabel input{vertical-align: middle!important;}
.kongge{margin-left: 5px;}
.ui.segment{overflow-y:scroll}
#user-leftinfo{width: 60%;float: left;}
#user-info-container2{width: 38%;height:915px;float: right;margin-left: 2%;cursor: pointer;
  border-radius: 5px;box-shadow: 1px 1px 4px 0 rgba(177, 11, 11, 0.15);}
.device-detail-container{height: 100%; padding: 0; margin: 0;}
#user-info-container3{width: 100%;margin-top: 15px;border-radius: 5px;
  box-shadow: 1px 1px 4px 0 1px 1px 4px 0 rgba(177, 11, 11, 0.15);
  padding: 10px 0 0;
}
.details-report-calendar{width: 100%;margin-top: 15px;border-radius: 5px;
  box-shadow: 1px 1px 4px 0 1px 1px 4px 0 rgba(177, 11, 11, 0.15);
  padding: 10px 0 0;
}
#user-info-container4{clear: both;}
.userbox1{width: 44%;height: 450px;cursor: pointer;
  border-radius: 5px;margin-right: 1%;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);float: left;}
.imgboxtotal{height: 300px;width: 100%;}
.imgbox1{float: left;width: 38%;margin-left: 10%}
.imgbox2{float: right;width: 38%;margin-right: 10%}
.seg-title2{text-align: left;;height: 26px;background: #eee;;margin-bottom: 3px;}
.box1header2{margin-left: 34px;}
.box1{text-align: center;height: 40px;margin:0!important;}
.box1header{font-weight: 700;line-height: 40px;}
.userbox2{width: 55%;height: 450px;float: left;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;}
.userbox4{clear: both;}
.userbox3{width: 100%;height: 450px;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;margin-top: 15px;text-align: left;}
.userbox3-text1box{height: 40px;border-bottom: 1px solid rgba(0,0,0,0.15);}
.userbox3-text1{margin-left: 34px;font-weight: 700;line-height: 40px;}
.userbox3-text2{margin-left: 34px;font-weight: 700;line-height: 40px;height: 40px;color: #9fa9b9;}
.userbox6{width: 100%;box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);border-radius: 5px;margin-top: 15px;}
.userbox6 img{width: 100%;}
.userbox3-imgbox{width: 100%;height: 370px;}
/* .userbox3-img1{width: 38%;float: left;margin-left: 7% }
.userbox3-img2{width: 38%;float: right;margin-right: 7% } */
.userbox3-img1{width: 38%;float: left;height: 90%;margin-left: 7%;}
.userbox3-img2{width: 38%;float: right;height: 90%;margin-right: 7%}
.userbox3-text2-span{float: left;}
.userbox3-text2-button{float: right;}
.react-calendar{width: 100%!important;line-height: 1.5em!important;}
.ico1{width: 10px;height: 10px;border:2px solid #dab4fd!important;border-radius: 20%;top:50%;margin-top:-5px;position: absolute}
.ico1text{margin-left: 20px;;line-height: 25px;}
.ico1box{height: 25px;position: relative;width: 80%;margin: 0 auto;margin-top: 10px;text-align: left;}
.ico2{width: 10px;height: 10px;border-radius: 50%;background-color: #e2d956;top:50%;margin-top:-5px;position: absolute}
.ico2text{margin-left: 20px;;line-height: 25px;}
.ico3{width: 10px;height: 10px;border-radius: 50%;background-color: rgb(252, 13, 27,1);top:50%;margin-top:-5px;position: absolute}
.ico3text{margin-left: 20px;;line-height: 25px;}
/* .react-calendar__tile--now{background-color:rgba(218, 180, 253,0.6)!important;} */
.react-daterange-picker__calendar .react-calendar__tile--rangeStart{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;}
.react-daterange-picker__calendar .react-calendar__tile--rangeEnd{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;
}
.react-calendar__tile--active{background-color:rgba(0,0,0,.1)!important;border: 1px solid #a989c5!important;color:black!important;border-radius: 20%;}
/* .react-calendar__tile--hasActive{color:white;background-color:#a989c5!important;} */
.react-calendar__tile--hover {background-color: #a989c5;}
.normalmetabase2{background-color: rgba(15, 127, 18,1)!important;color:white!important;}
.normalmetabase{background-color: rgba(15, 127, 18,0.6)!important;color:white!important;}
.yellowmetabase2{background-color: rgb(255, 253, 56,1)!important;color:rgb(94, 29, 29)!important;}
.yellowmetabase{background-color: rgb(255, 253, 56,0.6)!important;color:black!important;}
.redmetabase2{background-color: rgb(252, 13, 27, 1)!important;color:white!important;}
.redmetabase{background-color: rgb(252, 13, 27, 0.6)!important;color:white!important;}
.react-daterange-picker__inputGroup__month{width: 28px!important;}
.react-daterange-picker__inputGroup__year{width: 48px!important;}
.react-daterange-picker__inputGroup__day{width: 28px!important;}
.urlstyle{float: right;}
.urlbox{width: 100%;text-align: center}
.haveimage{border:2px solid #dab4fd!important;border-radius: 20%;}
.device-detail-container{padding-left: 20px!important;padding-right: 20px!important;}
.complianceBlank{width: 100%;height: 310px;}
.timeMore{background-color: rgb(78, 25, 116)!important;color:white!important;}
@media screen and (min-width: 1650px) {
    #svg_style{width: 80%!important;height: 80%!important;}
}
.status_userbox1{
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
}
.footTitle{text-align: left;height: 44px;background: #eee;line-height: 44px;}
.right_footTitle{
    display: inline-block;
    width: 50%;
}
.left_footTitle{
    display: inline-block;
    width: 50%;
}
.footTitle_box{text-align: center;}

.calendar_userbox2 {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.ico4 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(78, 25, 116);
  top: 50%;
  margin-top: -5px;
  position: absolute;
}
.ico4text {
  margin-left: 20px;
  line-height: 25px;
}
.timeMore {
  background-color: rgb(78, 25, 116) !important;
  color: white !important;
}
.timeMoreLast {
  background-color: rgba(78, 25, 116, 0.6) !important;
  color: white !important;
}

/*.react-calendar__tile--active {*/
/*  background-color: rgba(0, 0, 0, 0.1) !important;*/
/*  border: 1px solid #a989c5 !important;*/
/*  color: black !important;*/
/*  border-radius: 50%;*/
/*  max-width: 9.5% !important;*/
/*  margin: 0 2% !important;*/
/*}*/

.change_calenDay_button{margin-right: 10px!important;margin-left: 10px!important;}
.photoBox{height: 330px;}
.swiperBoxNew{width: 50%!important;}
.swiperP{border-left: 1px solid #ababab;font-size: 14px;margin-bottom: 0px;height: 30px;line-height: 30px;}
.swiperimg{width: 100%;text-align: center;height: 300px;float: left;object-fit: cover;}
.totalbox{height: 300px;}
.bigImage{border: 1px solid #ababab;box-shadow: 1px 1px #ababab;
    width: 700px;height: 500px;z-index: 9999;top: 50%;margin-top: -225px;
    position: absolute;left: 50%;margin-left: -350px;background-color: #fff;}
.swiper-container{z-index: 0!important;}
.closeIcon{height: 36px;background-color: #f0f0f0;text-align: right;}
.contentBox{height: 400px;width: 80%;margin-top: 34px;margin-bottom: 30px;margin-left: 10%;
    margin-right: 10%;}
.swiperBigerimg{width: auto;float: left;max-height: 400px;}
.swiperDate{width: 40%;float: right;}
.slick-list{margin-left: 2%!important;margin-right: 2%!important;}
.slick-prev {left: 0!important;z-index: 99999;}
.slick-next {right: 0!important;z-index: 99999;}
.slick-prev:before,.slick-next:before {color: #000!important;}
.clickIcon{line-height: 36px;}
.close{cursor: pointer}
[tabindex="-1"]:focus {
    outline: none!important;
}
#zmage{z-index: 999999999!important;}
.bli_Select{width: 130px;}
.invoice_Select{width: 230px;}
.search_time_Box{height: 32px;width: 100px!important;}
.search_time_label{margin-left: 10px;}
.billing_search_icon{margin-left: 10px!important;cursor: pointer;}
.bli_Select_half_month{width: 80px;}
.tableFooterRow {
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: inline-table;
  color: white;
  font-weight: 600;
  background-color: rgb(45, 1, 114);
  margin-top: -15px;
}

.optionStyle {
  width: 200px;
}

.check_all > .ant-checkbox .ant-checkbox-inner {
  border-width: 2px;
}

.info{
    float: left;height: 480px;
}
.title{background-color: #210738;
    height: 30px;line-height: 30px;
    color: #fff; font-weight: 800;
}
.patientContent{width: 100%;}
.patient_info_title{font-size: 12px;}
.patient_info_details{font-size: 12px;}
.site-collapse-custom-panel {
    border-radius: 0 !important;
}
.site-collapse-custom-panel > .ant-collapse-header {
    padding: 0 !important;
}

.site-collapse-custom-panel-overflow-visible .ant-collapse-content-active {
    overflow: visible;
}
.patient_info_box {
  height: 32% !important;
}
.sym_box {
  height: 150px;
  margin-top: 4px;
  overflow: auto;
}
.notes_box {
  height: 330px;
  overflow: auto;
}
.note_add_button {
  text-align: right;
}
.note_title_box {
  background-color: #210738;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-weight: 800;
}
.note_title {
  float: left;
  text-align: right;
  width: 55%;
}
.note_add_button {
  float: right;
}
.note_button {
  cursor: pointer;
  border-radius: 0.28571429rem;
  height: 30px !important;
  padding: 0 1.5em 0 1.5em !important;
  margin: 0px !important;
  background-color: rgba(99, 50, 141, 0.9);
  color: #fff;
  font-weight: 800;
}
.edit_button {
  cursor: pointer;
  border-radius: 0.28571429rem;
  height: 30px !important;
  padding: 0 1.5em 0 1.5em !important;
  margin: 0px !important;
  background-color: rgba(202, 203, 205, 0.9);
  font-weight: 800;
}
.clear_both {
  clear: both;
}
.info_sym {
  height: 40%;
}
.patient_title {
  float: left;
  text-align: right;
  width: 60%;
}

.note_comments{height: 150px;}
.note_title_table{color: #69c;font-weight: 600;cursor: pointer;}

.two_line_ellipses {
	display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}

.linkText{cursor: pointer;color: #69c;font-weight: 600;}
.linkText:hover{text-decoration: underline;}
.confirm-dlg {
  display: inline-block; }

.confirm-dlg {
  display: inline-block; }

.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #fff;
}

.device-detail-container #meta-grid {
  margin-bottom: 10px;
}

.device-detail-container .user-info-container .ui.grid > .stretched.row {
  padding: 0.2rem 0;
}
.device-detail-container
  .user-info-container
  .ui.grid
  > .stretched.row
  > .column {
  padding: 0.4rem;
}

.device-detail-container .user-info-container .seg-title {
  margin-bottom: 0.5rem;
}

.device-detail-container .wear-status-container .ui.grid {
  margin-top: 0.4rem;
}
.device-detail-container .wear-status-container .ui.grid > .row {
  padding: 0.2rem 0;
}
.device-detail-container .wear-status-container .ui.grid > .row > .column {
  padding: 0.4rem;
}

.device-detail-container #sock-status-container table {
  font-size: 1rem;
}
.device-detail-container #sock-status-container table td {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.device-detail-container #canvas-container {
  width: 100%;
}
.device-detail-container #canvas-container #sock-realtime {
  width: 100%;
  height: 100%;
}

.device-detail-container .wear-history-container {
  margin: 0.5rem 0;
}
.device-detail-container .wear-history-container .lbl-wear-history-start-date {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
.problemdetail {
  text-align: left;
}
.ui.segment {
  height: auto !important;
}
.seg-title {
  text-align: left;
  margin-bottom: 30px;
}
.floattext {
  text-align: left;
  width: 100%;
}
.lefttext {
  width: 49%;
  float: left;
  margin: 0;
}
.righttext {
  width: 49%;
  float: right;
  margin: 0;
}
.Statistics-container {
  padding: 20px;
}
#return-to-device-list {
  position: fixed;
  right: 0;
  z-index: 999;
}
/* .label_sirenId_box{font-weight: 700;color: rgba(0,0,0,.6);font-size: 16px;background-color: #e8e8e8;width: 140px;height: 35px;line-height: 35px;border-radius: 5px;margin-bottom: 5px;} */
.label_sirenId_box{margin-bottom: 25px;text-align: left;}
.label_sirenId{font-size: 16px!important;padding: 0px;margin: 0px;left: 0px!important;}
.noResock{background-color: #e8e8e8;}
.confirm-dlg {
  display: inline-block; }

.confirm-dlg {
  display: inline-block; }

.reviewSuccess_container {
  background-color: #248014;
  width: 100%;
  height: 54px;
  position: absolute;
  top: 0px;
  z-index: 999;
  margin-left: -20px !important;
  margin-right: -20px;
  color: #fff;
}
.clear_both {
  clear: both;
}
.reviewSuccess_left {
  float: left;
  margin-left: 20px;
  line-height: 54px;
}
.reviewSuccess_right {
  float: right;
  line-height: 54px;
  margin-right: 20px;
}
.reviewSuccess_doctorId {
  margin-left: 18px;
}
.reviewSuccess_right_close {
  font-size: 16px;
  cursor: pointer;
}
.reviewFail_container {
  background-color: red;
  width: 100%;
  height: 54px;
  position: absolute;
  top: 0px;
  z-index: 999;
  margin-left: -20px !important;
  margin-right: -20px;
  color: #fff;
}

.weight-flex-info {
  display: flex;
  height: 330px;
}
.weight-table-info {
  border-radius: 5px;
  /*border: 1px solid #ababab;*/
  width: 50%;
  float: left;
  margin-right: 10px;
  overflow: auto;
}
.weight-chart-info {
  border-radius: 5px;
  /*border: 1px solid #ababab;*/
  width: 50%;
  float: right;
  margin-left: 10px;
}

.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.inputTotal {
  display: block;
  width: 250px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 20px;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
}
#finishIcon {
  font-size: 100px;
  color: #4a90e2;
}
.inputContent {
  width: 250px;
}
.selectStyle {
  width: 250px;
  height: 40px;
  -webkit-appearance: menulist-button;
}
.newSite_box {
  width: 100%;
  margin: 0 auto;
}
.success_box{
  margin: 0 auto;
  text-align: center;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.checkTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
}
#siteIcon {
  font-size: 130px;
  color: #4a90e2;
  margin-bottom: 60px;
  margin-top: 20px;
}
.siteTitle {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.finishtext {
  font-weight: 500;
  margin-bottom: 10px;
}
.createButton {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-top: 30px!important;
}
.doctorAccount {
  border: 1px solid #ababab;
  width: 80%;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #ababab;
}
.createAccount {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-bottom: 10px;
  margin-left: 200px;
}
.customHeight {
  height: 10px;
}
#failedIcon {
  font-size: 80px;
  color: #4a4a4a;
  margin-bottom: 30px;
  margin-top: 80px;
}
.addUnderline{text-decoration: underline;}
.checkbox_label{margin-right: 20px;}
.checkbox_box{vertical-align:middle!important; margin-top:0!important;}
.site-add-icon{cursor: pointer;margin: 0!important;padding: 0!important;}
.add_site_button{background-color: #f5f6f7;cursor: pointer;border: 1px solid #ccd0d5;
  border-radius: 2px;justify-content: center;font-size: 12px;height: 18px;
  line-height: 18px;text-align: center;vertical-align: middle;padding: 0 8px;color: #4b4f56;
}
.staff_log_tip{font-size: 16px;}
.newSiteBox{margin: 0 auto;width: 72%}

*{padding: 0;margin: 0;}
.box-list-container .box-controll-container {
  margin-bottom: 10px; }
  .box-list-container .box-controll-container button {
    float: right; }

.box-list-container h2 {
  display: inline-block; }

#box-search-bar {
  float: right;
  margin-left: 10px; }

.search-item-container .search-items {
  margin: 5px 0;
  width: 100%; }

#search-bar {
  font-size: 14px; }
  #search-bar + i {
    font-size: 14px; }
.box-list-container{padding:20px!important;}
.boxlistdiv{text-align: center;}
.add_import_box{text-align: right;margin-bottom: 10px;margin-top: 10px;}

#StaffList .-previous {
  visibility: hidden;
}

#StaffList .-pageInfo {
  visibility: hidden;
}
.selectContainer{position: relative;border: 1px solid #d9d9d9;border-radius: 4px;height: 32px;padding: 0 15px 0 10px;display: inline-block;}
.selectBox{ background-color: #fafafa;padding: 0 10px 0 10px;border: 1px solid #e8e8e8;
  border-radius: 2px;}
.selectBox_bk{ background-color: #f5f5f5;padding: 0 10px 0 10px;}
.CloseOutlined{color: rgba(0, 0, 0, 0.45); font-weight: bold; font-size: 12px;}
.selectValue{font-size: 14px; color: rgba(0, 0, 0, 0.65);
  margin-right: 4px;
  height: auto;}
.selectValuesBox{margin-top: 3px;}
.Checkbox{margin-left: 8px;}
.CheckboxGroupBox{margin-top: 4px;margin-left: -12px;background-color: #fff;z-index: 999;height: 250px;overflow-y: auto;display: block;}
.selectInputSearch{width: 214px;margin-top: 20px;margin-bottom: 10px;margin-left: 8px;border-radius: 4px;}
.blanck_SelectBox{display: inline-block;margin-left: 5px;padding: 0 10px 0 10px;}

.tab_container{text-align: left;border-bottom: 1px solid rgba(34,36,38,.15);margin-bottom: 30px;padding: 0px 0px 12px 0px;}
.tab_nav-active{margin: 0 32px 0 0;cursor: pointer;font-size: 14px;padding: 12px 16px;
    line-height: 1.5;font-weight: 500;color: #1890ff;border-bottom: 2px solid #1890ff;}
.tab_nav{margin: 0 32px 0 0;cursor: pointer;font-size: 14px;padding: 12px 16px;
    line-height: 1.5;font-weight: 500;color: rgba(0,0,0,.65);}
.change99457BilledTip{margin-bottom: 0px;}
.fixedButtonHeight{height: 36px;}
.Multiple_Box{font-size: 14px;color: rgba(0, 0, 0, 0.65);margin-top: 3px;}
.summary_des_container{border: 2px solid rgba(0,0,0,0);border-radius: 5px;margin-bottom: 20px;margin-top: 30px;align-items:center; display: -webkit-flex;height: 80px;position: relative;}
.report_des_container_dark{background-color: #4F1974;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;margin-bottom: 20px;align-items:center; display: -webkit-flex;height: 80px;position: relative;width:300px;}
.report_des_container_light{background-color: #B892D2;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;margin-bottom: 20px;align-items:center; display: -webkit-flex;height: 80px;position: relative;width:300px;}
.summary_des_box{background-color: #f3f3f5;display: inline-block;margin-left: 10px;margin-right: 10px;height: 80px;width:200px;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;}
.summary_des_number{font-weight: 700;color: #000;font-size: 20px;margin-bottom: 0px;text-align:left;}
.summary_des_title{font-weight: 500;color: #000;font-size: 12px;margin-bottom: 0px;}
.summary_icon{color: rgb(45, 1, 114)!important;font-size: 30px;position: absolute;top: 6px;right: 6px;}
.add_site_icon{color: rgb(45, 1, 114)!important;font-size: 25px;position: absolute;top: 6px;right: 6px;margin-top: 7px;margin-right: 10px;}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month{background: #B892D2;border-radius: 50%;width: 40px;height: 40px;line-height: 40px;}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover{background: #B892D2;border-radius: 50%;width: 40px;height: 40px;line-height: 40px;}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year{background: #B892D2;border-radius: 50%;width: 50px;height: 50px;line-height: 50px;}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover{background: #B892D2;border-radius: 50%;width: 50px;height: 50px;line-height: 50px;}
.popContent{color:#4A4A4A}
.popTitle{margin-bottom: 0px;color: #000;font-weight: bold;}
.popBox{text-align: left;background-color: #f3f3f5;padding: 30px;}
.popContainer{margin-bottom: 30px;}
.popHeader{margin-top: 30px;margin-bottom: 15px;margin-left: 30px;}
.tab_title{color: #000;font-size: 18px;padding: 0px 16px;}
.tab_title_box{margin-top: 14px!important;margin-bottom: 20px;}
.selectContainer_unbilled{margin-top: 2px;}
.report_des_container{align-items:center; display: -webkit-flex;height: 80px;position: relative;}


*{padding: 0;margin: 0;}
.box-list-container .box-controll-container {
  margin-bottom: 10px; }
  .box-list-container .box-controll-container button {
    float: right; }

.box-list-container h2 {
  display: inline-block; }

#box-search-bar {
  float: right;
  margin-left: 10px; }

.search-item-container .search-items {
  margin: 5px 0;
  width: 100%; }

#search-bar {
  font-size: 14px; }
  #search-bar + i {
    font-size: 14px; }
.box-list-container{padding:20px!important;}
.boxlistdiv{text-align: center;}
.add_import_box{text-align: right;margin-bottom: 10px;margin-top: 10px;}
/* .checkbox_line{height: 1px;background-color: #000;width: 50%;margin-top: 2px;margin-bottom: 2px;} */
.check_all_box{margin-left: 5px;}
.ant-checkbox-group-item{display: block!important;}
.check_group_box{margin-left: 30px;}

.report_table_box{text-align: left;}
.report_table_box_one{text-align: left;}
.report_title_box_two{text-align:center;}
.report_title_box_three{text-align: right;}
.report_title_box{display: inline-block;width: 33%;}
.report_title_box_four{text-align: left;margin-top: 6px;margin-bottom: 4px;}
.report_title{font-weight: 900;color: #000;}
#detail_report_dowload_container{padding-top: 70px;}
.report_table{border: 1px solid #000!important;border-bottom: none!important;
    border-radius: 0!important;margin-top: 0px!important;}
.report_table_th_first{border-radius: 0!important;border-bottom: 1px solid #000!important;background-color: #b7b7b7!important;}
.report_table_th{border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #b7b7b7!important;}
.report_table_th_last{border-radius: 0!important;}
.report_table_td_first{border-bottom: 1px solid #000!important;}
.report_table_td{border-bottom: 1px solid #000!important;border-left: 1px solid #000!important;}
.detail_report_title_content{margin-left: 5px;color: #000;}
.details_report_table{margin-bottom: 30px!important;}
.user_report_title{color: #000;}
.user_report_title_header{color: #000;text-align: center;font-size: 24px;margin-top: 30px;margin-bottom: 30px;}
.user_report_table_th_first{border-top: 1px solid #000!important;border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #cadbf6!important;background-size: 100%;width: 10%;font-weight: 700;padding: .92857143em .78571429em;}
.user_report_table_th{border-top: 1px solid #000!important;border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #cadbf6!important;background-size: 100%;font-weight: 700;padding: .92857143em .78571429em;}
.user_report_table_th_four{width: 40%;}
.user_report_table_td_first{border-bottom: 1px solid #000!important;padding: .78571429em;}
.user_report_table_td{border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;padding: .78571429em;}
.report_table_box{text-align: left;}
.report_table{margin-top: 0px!important;text-align: center;color: rgba(0,0,0,.87);width: 100%;}
.report_table_solid{margin-top: 0px!important;text-align: center;color: rgba(0,0,0,.87);width: 100%;border:solid; border-width:0px 0px 1px 0px;}
.checkbox_img{width: 19px;height: 19px;position: absolute;top: 50%;left: 50%;margin-left: -9.5px;margin-top: -9.5px;}
.user_report_table_td{height: 100px;}
.user_report_table_td_solid{height: 100px;border:solid; border-width:1px 1px 3px 1px;}
.left_patientLog_box{float: left;width: 25%;}
.right_patientLog_box{float: right;width: 75%;}
.patientLog_grid{height: 100%;}
.patientLog_segment{height: 100%!important;}
.patientLog_log_text{position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    padding: 1em 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);height: 100%;text-align: left;overflow-y: auto;overflow-x: auto;}
#user-info-container {
  width: 100% !important;
  margin-top: 20px;
}
.device-detail-container {
  padding: 20px;
}
.search_patient_container {
  margin-bottom: 10px;
  text-align: left;
}
.point_input {
  width: 60px;
}

.point_btn {
  width: 100px;
}

.userid_container_box {
  margin-left: 10px;
  text-align: left;
  margin-bottom: 10px;
}
.time_picker_box {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}
.time_picker_span {
  margin-left: 10px;
  margin-right: 10px;
}
.Segment_simulate {
  overflow-y: hidden !important;
}
.simulate_sock_right_box {
  width: 45%;
  float: right;
  text-align: left;
}
.simulate_sock_left_box {
  width: 45%;
  float: left;
}

.simulate_sock_right_box_bk {
  width: 50%;
  float: right;
  text-align: left;
}
.simulate_sock_left_box_bk {
  width: 40%;
  float: left;
}

.simulate_sock_import_btn {
  margin-bottom: 20px;
}

.simulate_ok_button {
  margin-top: 10px !important;
  width: 100px;
}
/* .simulate_upload_button{margin-left: 13%!important;} */
.time_picker_label {
  margin-right: 10px;
}
.simulate_sock_text {
  width: 100%;
  height: 520px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  text-align: left;
  overflow-y: scroll;
  margin-top: 10px;
}
.patient_note_input {
  width: 80%;
  height: 300px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  overflow-y: scroll;
  margin-top: 10px;
  display: inline-block;
  white-space: pre-wrap;
  padding-left: 20px;
  padding-top: 20px;
}
.patient_note_input_new {
  width: 20%;
  height: 200px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.3rem;
  overflow-y: scroll;
  margin-top: 10px;
  display: inline-block;
  white-space: pre-wrap;
  padding-left: 20px;
  padding-top: 20px;
  float: left;
  margin-left: 30%;
}
.files_box {
  margin: 0 0.25em 0 10% !important;
}
.simulate_sock_center_box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -14px;
  margin-top: -11px;
}
.right_icon {
  cursor: pointer;
}
.simulate_button_box {
  position: relative;
  margin-top: 50px;
  text-align: right;
  margin-right: -10%;
}
.simulate_download_button {
  position: absolute;
  left: 72.5%;
  bottom: 0px;
}

.confirm-dlg {
  display: inline-block; }

.remoteConnect_label{margin-right: 10px;display: inline-block;width: 78px;text-align: right;}
.remoteConnect_left{display: inline-block;width: 50%;text-align: center;}
.remoteConnect_right{display: inline-block;margin-left: 16px;}
.remoteConnect_container{text-align: center;}
.remoteConnect_tip{text-align: left;margin-bottom: 20px;}
.lpn-list-board {
  border-radius: 5px;
  border: 1px solid #ababab;
}

.need-assign-info {
  border-radius: 5px;
  border: 1px solid #ababab;
  width: 50%;
  float: left;
  margin-right: 10px;
}

.site-assign-info {
  border-radius: 5px;
  border: 1px solid #ababab;
  /*width: 50%;*/
  /*float: right;*/
  /*margin-left: 10px;*/
}

.flex-info {
  display: flex;
  margin-top: 20px;
  height: 400px;
}

.confirm-dlg {
  display: inline-block; }
.cofirmSiteId{margin-bottom: 0px;}
.cofirmBold{color: #000;margin-left: 2px;}

.removeSite{position: absolute;margin-top: 13px!important;cursor: pointer;}
.resetPwd_eyeIcon{position: absolute;top: 50%;margin-top: -7px!important;right: 10px;}
.resetPwd_input{width: 68%;}
.resetPwd_Box{position: relative;float: left;height: 40px;width: 70%;}
.resetPwd_Label{float: left;line-height: 40px;color: #000;width: 28%;margin-right: 4%;text-align: right;font-size: 12px;
    font-weight: 600;}
.resetPwd_Modal{width: 566px!important;}
.resetPwd_checkIcon{position: absolute;}
.resetPwd_Tip_Box{float: right;width: 28%;height: 40px;line-height: 40px;}
.resetPwd_match_tip{color: green;float: right;width: 80%;margin-top: -10px;}
.resetPwd_error_Tip{width: 80%;color: red;margin-left: 22%;}
.guide-icon-no{top: 0!important}
.Guide_Dropdown{height: 54px;}
.guide-content{height: 54px!important;}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.app_menu_checkbox{margin-left: 0px!important;}

#SMSDripList .-previous {
	visibility: hidden;
}

#SMSDripList .-pageInfo {
	visibility: hidden;
}
#new-account {
	margin: 40px 15%;
	text-align: left;
}

#new-account form .ant-form-item-label {
	line-height: unset;
}

#new-account .form-section:first-child {
	margin-top: 20px;
}

#new-account .form-section {
	margin-top: 42px;
}

#new-account form .form-item {
	margin-bottom: 0;
}

#new-account .phone-button {
	height: 40px;
}

#new-account .phone-input {
	width: 100%;
	height: 40px;
	border-color: rgb(217, 217, 217);
	font-size: 16px;
}

#new-account .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #06C791;
}

#new-account .ant-steps-item-finish .ant-steps-item-icon {
	border-color: #06C791;
	background-color: #06C791;
}
#new-account .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #06C791;
}
#new-account .ant-steps-item-process .ant-steps-item-icon {
	background-color: #06C791;
	border-color: #06C791;
}

#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
	color: #06C791;
}
#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
	border-color: #06C791;
}
#new-account .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
	color: #06C791;
}

#new-account .anticon {
	display: inline-block;
	color: white;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

