.reset-image {
    margin: 0 auto;
    width: 650px !important;
    height: 650px !important;
}
.radioLabel {
    margin-left: 16px;
}
.closebutton {
    padding: 0;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
}
.closeicon {
    margin: 0 !important;
    padding: 0 !important;
}
.submitbutton {
    width: 200px;
    height: 50px;
    border: 1px solid #d1d1d1;
    color: #6699cc;
    border-radius: 5px;
    cursor: pointer;
}
.ImageBox {
    padding: 5% !important;
}
.radio {
    margin-left: 24%;
    margin-bottom: 10px;
    width: 100%;
}
.leftImageBox {
    width: 46%;
    float: left;
    background-color: #d8d8d8;
    height: 350px;
}
.rightImageBox {
    width: 46%;
    float: right;
    background-color: #d8d8d8;
    height: 350px;
}
.viewImage {
    width: 100%;
    height: 350px;
}
.commentLabel {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.commentInput {
    margin-left: 5px;
    margin-top: 20px;
    width: 50%;
    height: 100px;
}