.lpn-list-board {
  border-radius: 5px;
  border: 1px solid #ababab;
}

.need-assign-info {
  border-radius: 5px;
  border: 1px solid #ababab;
  width: 50%;
  float: left;
  margin-right: 10px;
}

.site-assign-info {
  border-radius: 5px;
  border: 1px solid #ababab;
  /*width: 50%;*/
  /*float: right;*/
  /*margin-left: 10px;*/
}

.flex-info {
  display: flex;
  margin-top: 20px;
  height: 400px;
}
