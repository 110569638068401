.add-box-form-container .sock-add-icon-container {
  display: inline-block; }
  .add-box-form-container .sock-add-icon-container .sock-add-icon {
    margin-left: 10px;
    cursor: pointer; }

.add-box-form-container .v-hidden {
  visibility: hidden; }

.add-box-form-container .v-visible {
  visibility: visible; }

.ant-checkbox .ant-checkbox-inner {
  /*width: 25px;*/
  /*height: 25px;*/
  /*background-color: red;*/
  border-color: #aaa;
}
