*{padding: 0;margin: 0;}
.box-list-container .box-controll-container {
  margin-bottom: 10px; }
  .box-list-container .box-controll-container button {
    float: right; }

.box-list-container h2 {
  display: inline-block; }

#box-search-bar {
  float: right;
  margin-left: 10px; }

.search-item-container .search-items {
  margin: 5px 0;
  width: 100%; }

#search-bar {
  font-size: 14px; }
  #search-bar + i {
    font-size: 14px; }
.box-list-container{padding:20px!important;}
.boxlistdiv{text-align: center;}
.add_import_box{text-align: right;margin-bottom: 10px;margin-top: 10px;}
/* .checkbox_line{height: 1px;background-color: #000;width: 50%;margin-top: 2px;margin-bottom: 2px;} */
.check_all_box{margin-left: 5px;}
.ant-checkbox-group-item{display: block!important;}
.check_group_box{margin-left: 30px;}
