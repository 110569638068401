body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactTable .rt-tbody .rt-tr.row-clickable:hover {
  border-top: solid 1px #bfc9cf;
  border-bottom: solid 1px #bec6ca;
  cursor: pointer;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,0.02);
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.sr-required:after {
  content: '*';
  color: red;
  margin-left: 4px;
}

.sr-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}