.step {
  /*border: 1px solid #ababab;*/
  width: 80%;
  margin: 0 auto;
  /*box-shadow: 1px 1px 1px #ababab;*/
  height: 450px;
}
.step_small_space {
  width: 80%;
  margin: 0 auto;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.inputTotal {
  display: block;
  width: 250px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 20px;
}
.step3InputTotal {
  width: 790px !important;
  margin-bottom: 0px !important;
}
.step3InputTotal600 {
  width: 600px !important;
  margin-bottom: 0px !important;
}
.inputLeftTotal {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 250px;
}
.inputrightTotal {
  float: right;
  margin-bottom: 20px;
  width: 250px;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}
#finishIcon {
  font-size: 100px;
  color: #4a90e2;
}
.inputContent {
  width: 250px !important;
  height: 40px !important;
}
.selectStyle {
  width: 250px;
  height: 40px;
}
.step3 {
  /*border: 1px solid #ababab;*/
  width: 80%;
  margin: 0 auto;
  /*box-shadow: 1px 1px 1px #ababab;*/
  height: 500px;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.checkTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
}
#finishIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 10px;
  margin-top: 100px;
}
.finishTitle {
  font-size: 20px;
  font-weight: 600;
}
.finishtext {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 50px;
}
.finishButton {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
}
.doctorAccount {
  border: 1px solid #ababab;
  width: 80%;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #ababab;
}
.createAccount {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-bottom: 10px;
  margin-left: 200px;
}
.customHeight {
  height: 10px;
}
#failedIcon {
  font-size: 80px;
  color: #4a4a4a;
  margin-bottom: 30px;
  margin-top: 80px;
}
.boldLabel {
  font-weight: 800;
}
#successIcon {
  font-size: 120px;
  color: #4a90e2;
  margin-bottom: 10px;
  margin-top: 100px;
}
#errorIcon {
  font-size: 120px;
  color: #4a4a4a;
  margin-bottom: 10px;
  margin-top: 100px;
}
.step3Title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px !important;
  margin-bottom: 0px !important;
}
.checktext {
  margin-bottom: 30px;
}
.DOBPicker {
  width: 250px;
  height: 32.63px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: none;
}
.step3 {
  height: 600px;
}
.step4_tip {
  margin-left: 25px;
  margin-bottom: 10px !important;
}
.step3_section_title {
  /* text-align: center;
  width: 600px !important; */
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.clear_both {
  clear: both;
}
.Patient_group {
  padding-top: 20px;
}
.Secondary_group {
  padding-top: 40px;
}
.Secondary_group_edit {
  padding-top: 30px;
}
.Site_group {
  padding-top: 40px;
}
.checkTotal .checkbox {
  font-size: 16px;
}
.checkTotal {
  font-size: 16px;
}
.select_month_box {
  width: 80px;
  min-width: 80px !important;
  height: 40px;
}
.select_day_box {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.error_birth_tip {
  color: red;
}

.newSite_box .react-tel-input input[type=tel] {
  width: 250px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 40px;
}
.step_li {
  margin-bottom: 30px;
  margin-left: 20px;
}
.step_li_new {
  margin-bottom: 20px;
  margin-left: 20px;
}
.step_li_no_margin_left {
  margin-bottom: 30px;
}
.step_tip_user {
  margin-bottom: 10px !important;
}
.img_border {
  border: 1px solid #ddd;
}
.selectFullWdith {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
}
.selectFullWdith .ant-select-selection__rendered {
  line-height: 40px !important;
}
.chekboxBottom {
  margin-bottom: 15px !important;
}
.PaInforBox {
  width: 790px !important;
  margin-bottom: 0px !important;
}
.selectMoreStyle {
  width: 290px;
  height: 40px;
}
.selectMoreStyle .ant-select-selection__rendered {
  line-height: 40px !important;
}
.inputLeftNewTotal {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 290px;
}
.inputRightNewTotal {
  float: right;
  margin-bottom: 20px;
  width: 290px;
}
.redAddressTip {
  color: red;
}
.inputDateContent {
  width: 250px;
  height: 40px;
}
.inputDateContent .ant-calendar-picker-input.ant-input {
  height: 40px;
}
.step3_confirm_title {
  text-align: left;
  width: 600px !important;
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.collapse_box {
  border-radius: 10px !important;
  width: 60%;
  margin-left: 20% !important;
  margin-top: 40px !important;
  text-align: left;
  padding: 0px 20px 0px 20px !important;
  color: #000;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
.collapse_panel_title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.panel_seRow_right {
  margin-left: 20%;
}
.collapse_panel_seRow {
  font-size: 16px;
  margin-bottom: 20px;
}
.collapse_panel_dis {
  /*margin-top: 20px !important;*/
  /*font-style: italic;*/
  width: 80%;
}
.collapse_panel_left {
  float: left;
  width: 70%;
}
.collapse_panel_right {
  float: right;
}
.App_Icon {
  display: block;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
}
.ant-collapse-content-box {
  color: #000 !important;
}
.panel_finish_content {
  font-size: 16px;
  margin-bottom: 30px;
  color: #000;
}
.panel_finish_title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px !important;
  margin-top: 50px !important;
  color: #000;
}
.panel_finish_icon {
  font-size: 100px !important;
  color: #02ce9c;
  width: 118px !important;
  line-height: 150px !important;
  height: 150px !important;
}
.inputTotal input {
  font-size: 14px;
}

.step_title {
  font-weight: 600;
  font-size: 18px;
}

.img_p {
  font-size: 14px;
  font-weight: normal;
  word-wrap: normal;
  width: 290px;
  padding-top: 10px;
  margin-left: 30px;
}


.ant-steps-item-process .ant-steps-item-title {
  font-weight: 400;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
  -ms-transform: none;
  font-weight: 900;
  transform: none;
}
