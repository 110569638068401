.type_label{margin-right: 40px;}
.type_box{margin-bottom: 20px;}
.mess_label{margin-right: 14px;position: absolute;top: 40%;}
.title_input{width: 300px;}
.mess_input{width: 300px;margin-left: 70px;height: 100px;}
.showSns_box{margin-bottom: 20px;}
.show_box{margin-left: 70px;}
.show_input{width: 200px;}
.modal_box{width: 600px!important;}
.red_tip{position: absolute;width: 180px;right: 0;top: 0px;font-size: 14px;letter-spacing: 0.3px;line-height: 1.5;color: rgba(0, 0, 0, 0.5);}
.normal_tip{position: absolute;width: 180px;right: 0;top: 80px;font-size: 14px;letter-spacing: 0.3px;line-height: 1.5;color: rgba(0, 0, 0, 0.5);}
.type_mess_box{position: relative;}
.mess_box{position: relative;}