.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #fff;
}

.device-detail-container #meta-grid {
  margin-bottom: 10px;
}

.device-detail-container .user-info-container .ui.grid > .stretched.row {
  padding: 0.2rem 0;
}
.device-detail-container
  .user-info-container
  .ui.grid
  > .stretched.row
  > .column {
  padding: 0.4rem;
}

.device-detail-container .user-info-container .seg-title {
  margin-bottom: 0.5rem;
}

.device-detail-container .wear-status-container .ui.grid {
  margin-top: 0.4rem;
}
.device-detail-container .wear-status-container .ui.grid > .row {
  padding: 0.2rem 0;
}
.device-detail-container .wear-status-container .ui.grid > .row > .column {
  padding: 0.4rem;
}

.device-detail-container #sock-status-container table {
  font-size: 1rem;
}
.device-detail-container #sock-status-container table td {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.device-detail-container #canvas-container {
  width: 100%;
}
.device-detail-container #canvas-container #sock-realtime {
  width: 100%;
  height: 100%;
}

.device-detail-container .wear-history-container {
  margin: 0.5rem 0;
}
.device-detail-container .wear-history-container .lbl-wear-history-start-date {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
.problemdetail {
  text-align: left;
}
.ui.segment {
  height: auto !important;
}
.seg-title {
  text-align: left;
  margin-bottom: 30px;
}
.floattext {
  text-align: left;
  width: 100%;
}
.lefttext {
  width: 49%;
  float: left;
  margin: 0;
}
.righttext {
  width: 49%;
  float: right;
  margin: 0;
}
.Statistics-container {
  padding: 20px;
}
#return-to-device-list {
  position: fixed;
  right: 0;
  z-index: 999;
}
/* .label_sirenId_box{font-weight: 700;color: rgba(0,0,0,.6);font-size: 16px;background-color: #e8e8e8;width: 140px;height: 35px;line-height: 35px;border-radius: 5px;margin-bottom: 5px;} */
.label_sirenId_box{margin-bottom: 25px;text-align: left;}
.label_sirenId{font-size: 16px!important;padding: 0px;margin: 0px;left: 0px!important;}
.noResock{background-color: #e8e8e8;}