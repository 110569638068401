.reviewSuccess_container {
  background-color: #248014;
  width: 100%;
  height: 54px;
  position: absolute;
  top: 0px;
  z-index: 999;
  margin-left: -20px !important;
  margin-right: -20px;
  color: #fff;
}
.clear_both {
  clear: both;
}
.reviewSuccess_left {
  float: left;
  margin-left: 20px;
  line-height: 54px;
}
.reviewSuccess_right {
  float: right;
  line-height: 54px;
  margin-right: 20px;
}
.reviewSuccess_doctorId {
  margin-left: 18px;
}
.reviewSuccess_right_close {
  font-size: 16px;
  cursor: pointer;
}
.reviewFail_container {
  background-color: red;
  width: 100%;
  height: 54px;
  position: absolute;
  top: 0px;
  z-index: 999;
  margin-left: -20px !important;
  margin-right: -20px;
  color: #fff;
}
