@media screen and (min-width: 1650px) {
    #svg_style{width: 80%!important;height: 80%!important;}
}
.status_userbox1{
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
}
.footTitle{text-align: left;height: 44px;background: #eee;line-height: 44px;}
.right_footTitle{
    display: inline-block;
    width: 50%;
}
.left_footTitle{
    display: inline-block;
    width: 50%;
}
.footTitle_box{text-align: center;}
