.calendar_userbox2 {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.ico4 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(78, 25, 116);
  top: 50%;
  margin-top: -5px;
  position: absolute;
}
.ico4text {
  margin-left: 20px;
  line-height: 25px;
}
.timeMore {
  background-color: rgb(78, 25, 116) !important;
  color: white !important;
}
.timeMoreLast {
  background-color: rgba(78, 25, 116, 0.6) !important;
  color: white !important;
}

/*.react-calendar__tile--active {*/
/*  background-color: rgba(0, 0, 0, 0.1) !important;*/
/*  border: 1px solid #a989c5 !important;*/
/*  color: black !important;*/
/*  border-radius: 50%;*/
/*  max-width: 9.5% !important;*/
/*  margin: 0 2% !important;*/
/*}*/
