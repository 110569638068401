.user_report_title{color: #000;}
.user_report_title_header{color: #000;text-align: center;font-size: 24px;margin-top: 30px;margin-bottom: 30px;}
.user_report_table_th_first{border-top: 1px solid #000!important;border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #cadbf6!important;background-size: 100%;width: 10%;font-weight: 700;padding: .92857143em .78571429em;}
.user_report_table_th{border-top: 1px solid #000!important;border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;background-color: #cadbf6!important;background-size: 100%;font-weight: 700;padding: .92857143em .78571429em;}
.user_report_table_th_four{width: 40%;}
.user_report_table_td_first{border-bottom: 1px solid #000!important;padding: .78571429em;}
.user_report_table_td{border-left: 1px solid #000!important;border-bottom: 1px solid #000!important;padding: .78571429em;}
.report_table_box{text-align: left;}
.report_table{margin-top: 0px!important;text-align: center;color: rgba(0,0,0,.87);width: 100%;}
.report_table_solid{margin-top: 0px!important;text-align: center;color: rgba(0,0,0,.87);width: 100%;border:solid; border-width:0px 0px 1px 0px;}
.checkbox_img{width: 19px;height: 19px;position: absolute;top: 50%;left: 50%;margin-left: -9.5px;margin-top: -9.5px;}
.user_report_table_td{height: 100px;}
.user_report_table_td_solid{height: 100px;border:solid; border-width:1px 1px 3px 1px;}